import * as React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import '../../styles/management.scss';

const RonRestum = () => {
  return (
    <Layout>
      <SEO
        title="RON RESTUM, BBA - Management | Ostara"
        description="NUTRIENT MANAGEMENT SOLUTIONS"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main className="management">
        <Container
          className="management-details-container"
          w="90%"
          paddingBottom={30}
          maxW={1694}
        >
          <Box className="manager-details">
            <div className="manager-details__text">
              <Link className="manager-details__back" to="/management">
                <ArrowBackIcon color="#044606" />
                BACK
              </Link>
              <StaticImage
                className="manager-details__img mobile"
                placeholder="transparent"
                src="../../images/ron-restum.jpg"
                alt=""
              />
              <Heading
                as="h2"
                color="#044606"
                className="manager-details__name"
              >
                Ron Restum,
                <br />
                BBA
              </Heading>
              <Text color="#044606" className="manager-details__position">
                Chief Commercial Officer
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Mr. Restum is a 40-year veteran of the agriculture industry,
                with extensive experience in agricultural inputs, especially
                specialty fertilizer, micronutrients, nitrogen stabilizers, and
                biological products. Working for Agrium (Nutrien) for over 30
                years in all three business units: retail, wholesale, and
                specialty fertilizer products, he managed operations and led
                sales of their North American agricultural specialty fertilizer
                and micronutrient product portfolios. In 2013 Mr. Restum
                transitioned to Koch Agronomic Services as Vice President, Sales
                North America where he directed sales for the company’s nitrogen
                stabilizer and specialty fertilizer portfolio. Mr. Restum also
                spent 3 years leading the global commercial efforts with a
                leader in the agricultural biostimulant market. Mr. Restum is a
                Certified Crop Advisor and received his Bachelor of Business
                Administration from Western Michigan University. Mr. Restum is a
                past chair of the Michigan Agri-Business Association and a
                charter member of the Micronutrient Manufacturers Assn.
              </Text>
            </div>
            <StaticImage
              className="manager-details__img desktop"
              placeholder="transparent"
              src="../../images/ron-restum.jpg"
              alt=""
            />
          </Box>
        </Container>

        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default RonRestum;
